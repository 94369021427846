<script>
// import {contentService} from '@/services'

export default {
  data() {
    return {
      show: false
    }
  }
}
</script>
