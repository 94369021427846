<script>
import PanelSearch from '@/components/common/PanelSearch'
import {isMobile, hasSmallScreen} from '@/utils'

export default {
  components: {
    PanelSearch
  },
  data() {
    return {
      lastScrollTop: 0,
      searchOpened: false,
      naviMobileSidebar: false
    }
  },
  created() {
    window.addEventListener('scroll', this.adaptNavi)
    window.addEventListener('touchmove', this.adaptNavi)
  },
  methods: {
    adaptNavi() {
      const st = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const mobileMenuVisible = isMobile() || hasSmallScreen()

      if (!mobileMenuVisible || (mobileMenuVisible && !this.naviMobileSidebar)) {
        if (st > this.lastScrollTop && st > 500) this.$el.classList.add('fixed-hide')
        else if (st < this.lastScrollTop) this.$el.classList.remove('fixed-hide')
      }

      this.lastScrollTop = st <= 0 ? 0 : st
    },
    toggleSearch() {
      if (!this.searchOpened) this.naviMobileSidebar = false
      this.searchOpened = !this.searchOpened
    }
  }
}
</script>
