import {
  faPause,
  faPlay,
  faChevronRight as fasChevronRight,
  faTimesCircle,
  faCheckCircle,
  faSlidersH
} from '@fortawesome/free-solid-svg-icons'


import {
  faChevronRight,
  faAngleDown,
  faArrowToBottom,
  faPrint,
  faMinus,
  faChevronUp,
  faCircle,
  faSearch as farSearch
} from '@fortawesome/pro-regular-svg-icons'

import {
  faBars,
  faTimes,
  faGlobe,
  faSearch,
  faMapMarkerAlt,
  faLongArrowRight,
  faLongArrowDown,
  faCommentAltEdit,
  faShieldCheck,
  faBookOpen,
  faCube,
  faCog
} from '@fortawesome/pro-light-svg-icons'

export default {
  fasChevronRight,
  faChevronRight,
  faPause,
  faPlay,
  faArrowToBottom,
  faChevronUp,
  faAngleDown,
  faCommentAltEdit,
  faLongArrowRight,
  faLongArrowDown,
  faMapMarkerAlt,
  faGlobe,
  farSearch,
  faSearch,
  faBars,
  faTimes,
  faTimesCircle,
  faCheckCircle,
  faCircle,
  faMinus,
  faPrint,
  faShieldCheck,
  faBookOpen,
  faCube,
  faCog,
  faSlidersH
}
