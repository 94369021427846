import Vue from 'vue'
import CaptionedImage from './CaptionedImage.vue'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'

createComponents('.captioned-image', {
  components: {CaptionedImage},
  mixins: [commonConfig(Vue), config(Vue)]
})
