import Vue from 'vue'
import Header from './Header.vue'
import store from '@/store'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'

createComponents('#header', {
  components: {Header},
  store,
  mixins: [commonConfig(Vue), config(Vue)]
})
