import Vue from 'vue'
import Splash from './Splash.vue'
import store from '@/store'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'

createComponents('#splash', {
  components: {Splash},
  store,
  mixins: [commonConfig(Vue), config(Vue)]
})
