<template>
  <aside class="panel-search row bg-white m-0 w-100 d-print-none" :class="{ 'moved-top' : !visible }">
    <div class="container py-4">
      <div class="row w-100">
        <div class="col-12 col-md-6">
          <form class="input-group cursor-pointer"
                :action="searchUrl"
          >
            <button ref="searchButton"
                    class="input-group-prepend bg-white border-0 d-block"
                    type="submit"
            >
              <span class="input-group-text pl-0"><icon :icon="['far', 'search']" size="lg" /></span>
            </button>

            <b-form-input v-if="visible"
                          v-model="searchTerm"
                          class="pl-1 py-3 h-auto"
                          autofocus
                          name="search"
                          autocomplete="off"
                          :placeholder="searchPlaceholder"
            />
          </form>
        </div>
      </div>
      <div v-if="recentTerms && recentTerms.length"
           class="row w-100 align-items-center"
      >
        <div class="col-auto invisible pl-0">
          <button class="input-group-prepend bg-white border-0 d-block" type="submit">
            <span class="input-group-text pl-0"><icon :icon="['far', 'search']" size="lg" /></span>
          </button>
        </div>
        <div class="col col-md-6 pl-1">
          <div v-for="(term, i) in recentTerms"
               :key="'search-recent-terms__'+i"
               class="d-flex align-items-center py-1 cursor-pointer justify-content-between justify-content-md-start"
               @click.prevent="searchFor(term)"
          >
            {{ term }}
            <icon :icon="['fal', 'times']" fixed-width class="text-muted ml-1 term-remove" @click.stop.prevent="removeTerm(term)" />
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    searchUrl: {
      type: String,
      required: false
    },
    searchPlaceholder: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchTerm: ''
    }
  },
  computed: {
    ...mapGetters([
      'recentTerms'
    ])
  },
  methods: {
    searchFor(t) {
      if (t && t.length) {
        this.searchTerm = t
        this.$nextTick(() => this.$refs.searchButton.click())
      }
    },
    removeTerm(t) {
      this.$store.commit('removeTermFromRecent', t)
    }
  }
}
</script>
