import Vue from 'vue'
import DocumentList from './DocumentList.vue'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'

createComponents('.documentlist', {
  components: {DocumentList},
  mixins: [commonConfig(Vue), config(Vue)]
})
