<script>
import AOS from 'aos'
import {isMobile} from '@/utils'

const DESKTOP_OPTIONS = {
  offset: 300,
  delay: 200,
  duration: 650
}

const MOBILE_OPTIONS = {
  duration: 500
}

export default {
  data() {
    return {
      maskClassLower: 'column-bg-mask__lower',
      maskClassHidden: 'column-bg-mask__hidden',
      options: DESKTOP_OPTIONS
    }
  },
  created() {
    document.addEventListener('aos:in:block-merge', () => {
      this.$refs['mask'].classList.add(this.maskClassLower)
    })

    document.addEventListener('aos:in:block-end', () => {
      this.$refs['mask'].classList.add(this.maskClassHidden)
    })

    document.addEventListener('aos:out', (details) => {
      // hack for bug with aos:out:block-merge event
      if (details.detail.dataset['aosId'] === 'block-merge') setTimeout(() => this.$refs['mask'].classList.remove(this.maskClassLower), 50) //hack for in/out race
      if (details.detail.dataset['aosId'] === 'block-end') setTimeout(() => this.$refs['mask'].classList.remove(this.maskClassHidden), 50) //hack for in/out race
    })

    if (isMobile()) this.options = MOBILE_OPTIONS
  },
  mounted() {
    AOS.init(this.options)
  }
}
</script>
