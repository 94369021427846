<script>
export default {
  methods: {
    changeCategory(e) {
      const params = new URLSearchParams(window.location.search)
      params.set('category', e.target.value)
      params.set('offset', '0')
      window.location.href = `${window.location.pathname}?${params}`
    }
  }
}
</script>
