export const initialState = {
  ui: {
    compare: {
      selected: []
    },
    search: {
      recent: []
    }
  },
  version: 1.1
}

export default initialState
