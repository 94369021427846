<script>
import {getUrlParam, triggerGlobalStoreSync} from '@/utils'
import {contentService} from '@/services'

export default {
  data() {
    return {
      search: getUrlParam('search') || '',
      searchDisplayed: '',
      noResults: false,
      items: [],
      selected: getUrlParam('category') || 'all',
      categories: [],
      currentPage: getUrlParam('page') || 1,
      rows: 0,
      perPage: 10,
      loading: false
    }
  },
  computed: {
    searchRequest() {
      const filters = {
        'meta.language': this.lang
      }
      if (this.selected !== 'all') {
        filters['meta.category'] = this.selected
      }
      return {
        indexSuffix: this.$el.dataset.index,
        query: this.search,
        offset: (this.currentPage - 1) * this.perPage,
        filters: filters
      }
    },
    inputPresent() {
      return !!(this.search || this.selected !== 'all')
    }
  },
  watch: {
    currentPage() {
      this.fetchResult()
    }
  },
  mounted() {
    this.categories = [
      {value: 'all', text: this.$el.dataset.allCategoriesLabel},
      ...JSON.parse(this.$el.dataset.categories).map(i => ({value: i.value, text: i.label}))
    ]
    if (this.inputPresent) this.fetchResult()
  },
  methods: {
    clearSearch() {
      this.search = ''
      this.selected = 'all'
      this.currentPage = 1
      this.fetchResult()
    },
    newSearchQuery() {
      this.currentPage = 1
      this.fetchResult()
    },
    changeCategory() {
      this.currentPage = 1
      this.fetchResult()
    },
    fetchResult() {
      this.loading = true
      this.updateQueryParams()
      contentService.fetchSearch(this.searchRequest).then(r => {
        if (r.data) {
          this.rows = r.data.total
          this.items = r.data.items
        } else {
          this.rows = 0
        }
        this.searchDisplayed = this.search
        this.noResults = this.inputPresent
        this.loading = false
      })
    },
    updateQueryParams() {
      const params = new URLSearchParams(window.location.search)
      params.set('search', this.search)
      params.set('page', this.currentPage)
      params.set('category', this.selected)
      this.$store.commit('addTermToRecent', this.search)
      triggerGlobalStoreSync()
      window.history.replaceState(window.location.origin, `Search results for ${this.search}`, `${window.location.pathname}?${params}`)
    }
  }
}
</script>
