import Vue from 'vue'
import SearchResults from './SearchResults.vue'
import store from '@/store'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'

new Vue({
  components: {SearchResults},
  store,
  mixins: [commonConfig(Vue), config(Vue)]
}).$mount('#searchResults')
