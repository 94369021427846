import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import initialState from './initial-state'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: initialState,
  mutations,
  actions,
  getters
})

const saveStore = state => {
  for (const prop in state) {
    if (state.hasOwnProperty(prop)) localStorage.setItem(prop, JSON.stringify(state[prop]))
  }
}

// subscribe to save store on change
store.subscribe((mutation, state) => {
  saveStore(state)
})

window.addEventListener('storageUpdated', () => {
  store.commit('restoreState', true)
})

store.commit('restoreState', true)

export default store
