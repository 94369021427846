<script>
export default {
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.odd === 'true') return 'bg-light'
    },
    print(e) {
      e.preventDefault()
      window.print()
    }
  }
}
</script>
