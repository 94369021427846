import Vue from 'vue'
import DealerLocator from './DealerLocator.vue'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'

createComponents('.dealer-locator', {
  components: {DealerLocator},
  mixins: [commonConfig(Vue), config(Vue)]
})
