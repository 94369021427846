import '@/scss/custom.scss'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library, dom} from '@fortawesome/fontawesome-svg-core'
import icons from '@/icons'

library.add(icons)
dom.watch()

const config = function (Vue) {
  const htmlEl = document.getElementsByTagName('html')[0]
  if (htmlEl) {
    const pageLang = htmlEl.getAttribute('lang')
    if (!Vue.prototype.lang) Vue.prototype.lang = pageLang
  }

  const bodyEl = document.getElementsByTagName('body')[0]
  Vue.component('icon', FontAwesomeIcon)

  if (bodyEl && bodyEl.dataset) {
    const srv = bodyEl.dataset.apiServers ? JSON.parse(bodyEl.dataset.apiServers) : null
    const apiToken = bodyEl.dataset.apiToken ? bodyEl.dataset.apiToken : null
    // const awsTraceId = bodyEl.dataset.traceId ? bodyEl.dataset.traceId : false

    if (!Vue.prototype.assetServer) Vue.prototype.assetServer = bodyEl.dataset.assetServer ? bodyEl.dataset.assetServer : ''
    if (!Vue.prototype.isMagnoliaEditMode) Vue.prototype.isMagnoliaEditMode = bodyEl.dataset.isEdit ?
      Boolean(bodyEl.dataset.isEdit) : false

    if (srv && !Vue.prototype.apiServers) {
      Vue.prototype.apiServers = srv

      Vue.prototype.$http.interceptors.request.use(c => {
        if(c.url && c.url.startsWith('%')) {
          let server = c.url.split('%')[1]
          c.url = c.url.replace('%' + server + '%', srv[server.toLowerCase()])

          if (apiToken) c.headers['Authorization'] = `Bearer ${apiToken}`
        }
        // if (awsTraceId) c.headers['x-amzn-trace-id'] = awsTraceId
        return c
      })
    }
  }

  return {
    beforeMount() {
      if(Vue.config) Vue.config.productionTip = false
      Vue.prototype.uuid = this.$el.dataset.uuid
      Vue.prototype.config = this.$el.dataset.config ? JSON.parse(this.$el.dataset.config) : {}
    },
    mounted() {
      setTimeout(() => { if (this.$el && this.$el.classList) this.$el.classList.remove('wait-init') }, 25)
    }
  }
}

export default config
