import axios from 'axios'
import {cacheAdapterEnhancer, throttleAdapterEnhancer} from 'axios-extensions'
import Vue from 'vue'

const _options = {
  headers: {'Cache-Control': 'no-cache'},
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, {enabledByDefault: true, cacheFlag: 'useCache'}))
}

const _devOptions = {
  adapter: function (config) {
    config.adapter = null
    return new Promise((resolve, reject) => {
      axios.request(config).then(r => {
        if (r.data && r.data.errorCode) {
          if (r.data.errorCode === 401) window.dispatchEvent(new Event('forceLogout'))
          reject({code: r.data.errorCode, response: r})
        }
        if (process.env.VUE_APP_DEV_THROTTLE > 0) {
          //throttle response to check loaders
          setTimeout(() => resolve(r), (Math.random() * process.env.VUE_APP_DEV_THROTTLE))
        } else resolve(r)
      }).catch(e => reject(e))
    })
  }
}

const _http = axios.create(process.env.VUE_APP_MODE === 'preview' ? _devOptions : _options)

export default _http

export function triggerGlobalStoreSync() {
  if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
    const event = document.createEvent('Event')
    event.initEvent('storageUpdated', true, true)
    window.dispatchEvent(event)
  } else {
    window.dispatchEvent(new Event('storageUpdated'))
  }
}

export function parseParameterArrayToString(arr) {
  return '[' + arr.map(x => '"' + x + '"').join(', ') + ']'
}

export function createComponents(_mountingPoint = null, _options) {
  if (_mountingPoint) {
    const els = document.querySelectorAll(_mountingPoint + ':not(.initialized)')

    if (els.length) {
      els.forEach(x => {
        x.classList.add('initialized')

        els.forEach(x => new Vue(_options).$mount(x))
      })
    }
  }
}

export function getUrlParam(name) {
  const params = new URLSearchParams(window.location.search)
  return params.get(name)
}

export function isMobile() {
  return typeof window.matchMedia === 'function' && (window.matchMedia('(max-width: 767px)').matches || window.matchMedia('(pointer:coarse)').matches)
}

export function hasSmallScreen() {
  return typeof window.matchMedia === 'function' && window.matchMedia('(max-width: 1080px)').matches
}
