<script>
export default {
  data() {
    return {
      add: {
        disabled: true,
        selected: null
      },
      compared: []
    }
  },
  watch: {
    'add.selected'(n, o) {
      if (n != o) this.add.disabled = !n
    }
  },
  created() {
    const params = new URLSearchParams(window.location.search)
    this.compared = params.get('selected') ? new Set(params.get('selected').split(',')) : new Set
  },
  methods: {
    handleAddProducts() {
      if (!this.add.disabled) {
        this.compared.add(this.add.selected)
        this.reloadComparator()
      }
    },
    handleRemoveProducts(val) {
      this.compared.delete(val)
      this.reloadComparator()
    },
    reloadComparator() {
      const params = new URLSearchParams(window.location.search)
      params.set('selected', [...this.compared].join(','))
      window.location.href = `${window.location.pathname}?${params}`
    },
    print(e) {
      e.preventDefault()
      window.print()
    }
  }
}
</script>
