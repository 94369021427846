<script>
export default {
  computed: {
    emptyProducts() {
      return 3 - this.selectedProducts.length
    },
    selectedProducts() {
      return this.$store.state && this.$store.state.ui.compare.selected ? this.$store.state.ui.compare.selected : []
    },
    selectedParams() {
      return this.selectedProducts.map(x => x.id).join(',')
    }
  },
  methods: {
    removeProduct(p) {
      this.$store.commit('removeComparedProduct', p.id)
    }
  }
}
</script>
