import Vue from 'vue'
import DoubleCta from './DoubleCta.vue'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'

createComponents('.double-cta', {
  components: {DoubleCta},
  mixins: [commonConfig(Vue), config(Vue)]
})
