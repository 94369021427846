export default {
  addComparedProduct(state, prod) {
    state.ui.compare.selected.push(prod)
  },
  removeComparedProduct(state, pid) {
    state.ui.compare.selected = state.ui.compare.selected.filter(x => x.id !== pid)
  },
  restoreState(state, force = false) {
    const stateRestored = {
      restored: true
    }
    const version = parseFloat(localStorage.getItem('version')) || 0

    if (force || (!state.restored && version === state.version)) {
      for (const prop in state) {
        if (state.hasOwnProperty(prop) && localStorage.getItem(prop)) stateRestored[prop] = JSON.parse(localStorage.getItem(prop))
      }
    }

    this.replaceState(Object.assign({}, state, stateRestored))
  },
  addTermToRecent(state, t) {
    if (t) state.ui.search.recent = [t, ...state.ui.search.recent.filter(x => x !== t)].slice(0, 5)
  },
  removeTermFromRecent(state, t) {
    if (t) state.ui.search.recent = state.ui.search.recent.filter(x => x !== t)
  }
}
