<script>
export default {
  data() {
    return {
      filter: new URLSearchParams(window.location.search).get('term') || '',
      filteredSections: [],
      filteredQuestions: []
    }
  },
  watch: {
    filter() {
      this.filterData()
    }
  },
  created() {
    this.filterData()
    setTimeout(() => this.$el.classList.remove('wait-load'), 10)
  },
  methods: {
    clearFilter() {
      this.filter = ''
    },
    filterData() {
      const questions = []
      const sections = new Set()

      if (this.config && this.config.sections) {
        for (const s of this.config.sections) {
          for (const q of s.questions) {
            if (q.text.includes(this.filter.toLowerCase())) {
              questions.push(q.id)
              sections.add(s.id)
            }
          }
        }
      }

      this.filteredSections = [...sections]
      this.filteredQuestions = questions
    }
  }
}
</script>
