import BootstrapVue from 'bootstrap-vue'
import http from '@/utils'
import api from '@/api/apiUrls'
import OverlayWrapper from '@/components/common/OverlayWrapper'

// only needed for IE11!
import 'url-search-params-polyfill'

export const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en-US'
const getUserTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

const _testMixin = function (Vue) {
  Vue.use(BootstrapVue)
  Vue.component('overlay-wrapper', OverlayWrapper)

  Vue.config.ignoredElements = ['bicon', 'b-icon']
  Vue.prototype.$http = http
  Vue.prototype.$api = api

  Vue.filter('trunc', (str, len = 55, clamp = '...') => {
    return str && str.length ? str.slice(0, len) + (len < str.length ? clamp : '') : str
  })

  Vue.filter('formatDate', val => {
    const ts = new Date(val)
    const options = {day: 'numeric', month: 'short', year: 'numeric'}
    return ts.toLocaleDateString(getNavigatorLanguage(), options)
  })

  Vue.filter('formatTime', val => {
    const ts = new Date(val)
    const options = {hour: 'numeric', minute: 'numeric'}
    return ts.toLocaleTimeString(getNavigatorLanguage(), options) + ' ' + getUserTimeZone()
  })

  Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  })

  return {}
}

export default _testMixin
