import Vue from 'vue'
import Accordion from './Accordion.vue'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'

createComponents('.accordion', {
  mixins: [commonConfig(Vue), config(Vue)],
  components: {Accordion}
})
