import Vue from 'vue'
import ProductList from './ProductList.vue'
import store from '@/store'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'

createComponents('.product-list', {
  components: {ProductList},
  store,
  mixins: [commonConfig(Vue), config(Vue)]
})
