import http from '@/utils'
import api from '@/api/apiUrls'

export const contentService = {
  fetchSearch
}

function getFilters(filters) {
  return Object.keys(filters)
    .map(f => `${f}=${filters[f]}`)
    .join('&')
}

function fetchSearch(request) {
  return http.get(api.SEARCH
    .replace('{indexSuffix}', request.indexSuffix)
    .replace('{query}', request.query)
    .replace('{offset}', request.offset)
    .replace('{filters}', encodeURIComponent(getFilters(request.filters)))
  )
}

