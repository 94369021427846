import Vue from 'vue'
import Breadcrumbs from './Breadcrumbs.vue'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'

createComponents('#breadcrumbs', {
  components: {Breadcrumbs},
  mixins: [commonConfig(Vue), config(Vue)]
})
