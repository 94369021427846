<script>
import ProductComparatorStripe from '../ProductComparatorStripe/ProductComparatorStripe'
import {mapGetters} from 'vuex'

export default {
  components: {
    ProductComparatorStripe
  },
  data() {
    return {
      products: [],
      compareModels: {}, // to keep v-models for compare checkboxes
      facetModels: {}, // to keep v-models for facet checkboxes
      facets: {
        init: [],
        productIds: [], // keep ids of product when filtering by facets
        props: {}, // keep all active facet props,
        disabled: [] // list of disabled facets while filtering
      }
    }
  },
  computed: {
    selectedIds() {
      return this.selected ? this.selected.map(x => x.id) : []
    },
    ...mapGetters([
      'selected'
    ])
  },
  watch: {
    'selectedIds'(n) {
      if (n) {
        for (const k of Object.keys(this.compareModels)) {
          if (!n.includes(k)) this.compareModels[k] = false
        }
      }
    },
    'facets.productIds'(pids) {
      const disabled = []
      if (pids.length) { // only if any products after filtering
        const filteredProducts = this.products.filter(x => pids.includes(x.id))

        for (const _fGroup of this.facets.init) {
          // check if facet group is used
          if (filteredProducts.filter(x => x.properties.filter(prop => prop.label === _fGroup.label).length).length) {
            // group used, checking not used values
            for (const g of _fGroup.values) {
              if (!filteredProducts.filter(x => x.properties.filter(prop => prop.label === _fGroup.label && prop.value === g).length).length) {
                // adding not used to disabled ones
                disabled.push(_fGroup.label + '_' + g)
              }
            }
          } else {
            // add whole group to disabled
            for (const g of _fGroup.values) {
              disabled.push(_fGroup.label + '_' + g)
            }
          }
        }
      }
      this.facets.disabled = disabled
    }
  },
  mounted() {
    this.products = Object.freeze(JSON.parse(this.$el.dataset.products))
    this.facets.init = Object.freeze(JSON.parse(this.$el.dataset.facets))

    // init productModels for checkboxes
    for (const p of this.products) this.compareModels[p.id] = []

    // init facetModels for checkboxes
    this.resetFacetModels()
    this.filterProducts()
  },
  methods: {
    toggleProduct(pid, e) {
      if (e.length) {
        this.$store.commit('addComparedProduct', this.products.filter(x => x.id === pid)[0])
      } else {
        this.$store.commit('removeComparedProduct', pid)
      }
    },
    toggleFacet(facet, e) {
      if (e && e.length) {
        this.enableFacet(facet)
      } else {
        this.disableFacet(facet)
      }
    },
    enableFacet(f) {
      const _label = f.label.toLowerCase()
      const _val = f.value.toLowerCase()

      if (_label.length && _val.length) {
        if (this.facets.props[_label]) this.facets.props[_label].push(_val)
        else this.facets.props[_label] = [_val]

        this.filterProducts()
      }
    },
    disableFacet(f) {
      const _label = f.label.toLowerCase()
      const _val = f.value.toLowerCase()

      if (_label && _val && _label.length && _val.length && this.facets.props[_label]) {
        if (this.facets.props[_label].length > 1) this.facets.props[_label] = this.facets.props[_label].filter(x => x !== _val)
        else delete this.facets.props[_label]

        this.filterProducts()
      }
    },
    clearFacets() {
      this.facets.productIds = []
      this.facets.props = {}
      this.resetFacetModels()
      this.filterProducts()
    },
    resetFacetModels() {
      for (const f of this.facets.init) {
        for (const v of f.values) {
          this.facetModels[f.label + '_' + v] = []
        }
      }
    },
    filterProducts() {
      this.facets.productIds = []
      const _facetProps = Object.keys(this.facets.props)

      if (_facetProps.length) { // only if any facets turned on
        for (const p of this.products) {
          let meetsAllFacetRequirements = true

          for (const _fGroup of _facetProps) {
            for (const _fGroupValue of this.facets.props[_fGroup]) {
              if (p.properties.filter(prop => prop.label === _fGroup && prop.value === _fGroupValue).length) {
                // it meet requirement for this group
                meetsAllFacetRequirements = meetsAllFacetRequirements && true
              } else {
                meetsAllFacetRequirements = false
              }
            }
          }

          // if product meets the facet requiremets add this as filtered
          if (meetsAllFacetRequirements) this.facets.productIds.push(p.id)
        }
      }
    }
  }
}
</script>
