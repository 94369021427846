<script>
export default {
  data() {
    return {
      show: false
    }
  },
  created() {
    this.show = window.navigator.userAgent.match(/MSIE|Trident/) !== null && !this.$cookies.get('upgradeBrowser')
  },
  methods: {
    setCookie() {
      this.$cookies.set('upgradeBrowser', true, 0)
    }
  }
}
</script>
