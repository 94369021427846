import Vue from 'vue'
import StickyCta from './StickyCta.vue'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'

const els = document.querySelectorAll('.sticky-cta:not(.initialized)')

if (els.length) {
  els.forEach(x => {
    x.classList.add('initialized')
    new Vue({
      components: {StickyCta},
      mixins: [commonConfig(Vue), config(Vue)]
    }).$mount(x)
  })

  setTimeout(() => document.querySelector('.sticky-cta.initialized').classList.remove('hidden'), 2500)

  window.addEventListener('scroll', toggleStickyCta)
  window.addEventListener('touchmove', toggleStickyCta)
}

function toggleStickyCta() {
  const els = document.querySelectorAll('.sticky-cta')
  const scrollPos = Math.max(window.scrollY || 0, window.pageYOffset || 0, document.body.scrollTop || 0)

  if (scrollPos > 300) {
    els[0].classList.add('hidden')
    els[1].classList.remove('hidden')
  } else {
    els[1].classList.add('hidden')
    els[0].classList.remove('hidden')
  }
}
