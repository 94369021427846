import Vue from 'vue'
import UpgradePopup from './UpgradePopup.vue'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

createComponents('#upgrade-popup', {
  components: {UpgradePopup},
  mixins: [commonConfig(Vue), config(Vue)]
})
