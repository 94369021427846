<script>
import {videoPlayer} from 'vue-video-player'
import 'videojs-contrib-hls'

export default {
  components: {
    videoPlayer
  },
  mounted() {
    // hack for WD-484, force rerender
    setTimeout(() => {
      document.body.style.overflowX = 'hidden'
      document.body.style.overflowY = 'visible'
      setTimeout(() => { document.body.style.overflow = 'visible' }, 500)
    }, 1500)
  }
}
</script>
