import Vue from 'vue'
import ArticleList from './ArticleList.vue'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'

createComponents('.articles', {
  components: {ArticleList},
  mixins: [commonConfig(Vue), config(Vue)]
})
