import Vue from 'vue'
import ProductDetails from './ProductDetails.vue'
import config from '@/mixins/componentConfiguration'
import commonConfig from '@/mixins/commonMixin'
import {createComponents} from '@/utils'

createComponents('.product-details', {
  components: {ProductDetails},
  mixins: [commonConfig(Vue), config(Vue)]
})
