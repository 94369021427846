<script>
// import {contentService} from '@/services'

export default {
  data() {
    return {
      loaded: false,
      currentActive: 0,
      isPaused: false
    }
  },
  watch: {
    loaded(n) {
      if (n) setTimeout(() => this.$el.classList.remove('wait-load'), 10)
    }
  },
  mounted() {
    this.hackRestart()
    setTimeout(this.hackRestart, 2000)
  },
  methods: {
    imageLoaded() {
      this.loaded = true
    },
    onSlideStart(s) {
      this.currentActive = s
      this.playSlide(s)
    },
    onSlideEnd(s) {
      const _allVideo = [...this.$el.querySelectorAll('video')]
      if (_allVideo.length) _allVideo.filter(x => x.pause())
      this.playSlide(s)
      this.updateState()
    },
    playSlide(s) {
      const slides = [...this.$el.querySelectorAll('.carousel-item')]
      const _newSlide = slides[s] || null
      if (_newSlide) {
        const _v = _newSlide.querySelector('video')
        if (_v) _v.play()
      }
    },
    togglePlay(e) {
      e.preventDefault()
      if (this.isPaused) this.$refs.carousel.start()
      else this.$refs.carousel.pause()
      this.updateState()
    },
    updateState() {
      this.isPaused = this.$refs.carousel.isPaused
    },
    setSlide(x) {
      this.currentActive = x
    },
    hackRestart() {
      if (this.$refs.carousel) this.$refs.carousel.restart = () => {}
    }
  }
}
</script>
